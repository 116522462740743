<template>
	<div class="tip-content">
		<div class="tip-info-icon-content">
			<span class="tip-info-text">i</span>
		</div>
		
		<label class="tip-title">{{$t('common.tips')}}</label>
		<label class="tip-sub-title">{{$t('home.withdraw')}}</label>
		
		<div class="column-center-content tip-info-content">
			<label class="tip-info-label">{{$t('home.withdrawAmount')}}:
				<span v-text="tipInfo.amount"></span>
			</label>
			<label class="tip-info-label">{{$t('tips.withdrawAddress')}}:</label>
			<div style="max-width: 200px ; white-space: normal; word-wrap: break-word;">
				<label style="margin-top: 5px; font-size: 18px; color: #888888;" v-text="tipInfo.address"></label>
			</div>
		</div>
		
		<div class="row-content tip-btn-content" style="justify-content: space-between;">
			<mt-button @click="closePop" type="danger" style="width: 80px;">{{$t('common.cancel')}}</mt-button>
			<mt-button @click="goWithdraw" type="primary" style="width: 80px;">{{$t('common.ok')}}</mt-button>
		</div>
	</div>
</template>

<script>
	import "@/styles/tips.css"
	
	export default {
		name: 'WithdrawConfirmTip',
		props: {
			tipInfo: {
				type: Object,
				default: () => {
					return {
						amount: 0,
						address: ''
					}
				}
			}
		},
		methods: {
			closePop() {
				this.$parent.$parent.closePop();
			},
			goWithdraw() {
				this.$parent.$parent.withdraw();
			}
		}
	}
</script>

<style>
</style>
